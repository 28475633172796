import React from 'react'
import { useMediaQuery } from 'usehooks-ts'

export default function Home() {
  const matches = useMediaQuery('(min-width: 768px)')

  return (
    <div className='home'>
      {matches && (
        <>
          <div className='video-container'>
            <video src='/videos/home.mp4' loop autoPlay muted />
          </div>
        </>
      )}


    </div>
  )
}
