import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Error from "./components/Error";
import { ProjectDetails } from "./components/ProjectDetails";
import About from "./components/About";
import Home from "./components/Home";

const root = ReactDOM.createRoot(document.getElementById("root"));

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <App />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/about",
          element: <About />,
        },
        {
          path: "/project/:projectUrl",
          element: <ProjectDetails />,
        },
      ],
    },
  ].map((route) => ({
    ...route,
    errorElement: <Error />,
  }))
);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
