import React, { Fragment, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import information from "../information.json";

export function ProjectDetails() {
    const { projectUrl } = useParams();
    const inputElement = useRef(null);

    useEffect(() => {
        inputElement.current.scrollIntoView({ behavior: "smooth" });
    }, [projectUrl]);

    const project = information.projects.find(
        (project) => project.url === projectUrl
    );

    if (!project) {
        throw new Error("There is no project: " + projectUrl);
    }

    const projectBlocks = project.detailsPageBlocks;

    projectBlocks.forEach((projectBlock) => {
        if (projectBlock.image && project.text) {
            throw new Error("Image and text cannot be in the same block");
        }
    });

    return (
        <div className="project-details" ref={inputElement}>
            {projectBlocks.map((block, index) => {
                const isImage = !!block.image;
                const isText = !!block.text || !!block.title;
                const isOnlyText = !!block.text || !block.title;
                const hasTitle = !!block.title;
                const isOnlyTitle = hasTitle && !isOnlyText;
                const isTextAfterMedia = index > 0 && projectBlocks[index - 1].image;

                if (block.fillSpace)
                    return <div style={{ width: "100%", clear: "left" }}></div>;

                const styles = {
                    width: `calc(${block.width} - 10px)`,
                    display: "inline-block",
                    marginRight: "10px",
                    backgroundColor: "transparent",
                    boxSizing: "border-box",
                    float: "left",
                };

                if (isImage) {
                    styles.backgroundImage = `url("/images/${block.image}")`;
                    styles.width = "100%";
                    styles.height = "100%";
                    styles.overflow = "hidden";
                }

                if (isText) {
                    delete styles.minHeight;
                    styles.paddingBottom = "inherit";
                    styles.marginTop = "5px";
                    styles.marginBottom = "5px";
                    styles.paddingTop = 0;
                }

                if (isOnlyTitle) {
                    styles.paddingBottom = "10px";
                }

                if (isOnlyText) {
                    styles.paddingTop = 0;
                    styles.marginBottom = "0px";
                }

                if (isTextAfterMedia) {
                    styles.paddingTop = "15px";
                }

                return (
                    <div
                        style={styles}
                        className={`box ${isText ? "project-text-box" : ""}`}
                        key={block.url}
                    >
                        {block.title && (
                            <h2 style={{ marginBottom: isOnlyTitle ? "0px" : "10px" }}>
                                {" "}
                                {block.title}
                            </h2>
                        )}
                        {block.text && (
                            <p>
                                <ReactMarkdown>{block.text}</ReactMarkdown>
                            </p>
                        )}
                    </div>
                );
            })}

            <div
                className="box clear-float"
                style={{
                    marginRight: "10px",
                    float: "left",
                    marginTop: "10px",
                    paddingBottom: "10px",
                }}
            >
                <h2 style={{ margin: 0 }}>Credits</h2>
                {Object.keys(project.credits).map((key) => {
                    return (
                        <Fragment key={key}>
                            <p style={{ marginTop: "10px", marginBottom: 0 }}>{key}</p>
                            {project.credits[key].map((element) => (
                                <p style={{ margin: 0 }} key={element}>
                                    {element}
                                </p>
                            ))}
                        </Fragment>
                    );
                })}
            </div>
            <div style={{ clear: "left", marginBottom: "10px" }}></div>
        </div>
    );
}
