import { Link, Outlet, useLocation } from "react-router-dom";
import "./App.scss";
import { ProjectList } from "./components/ProjectList";
import { validateInformationFormat } from "./utils/information.util";
import About from "./components/About";

function App() {
  validateInformationFormat();
  const location = useLocation();

  return (
    <div className="root">
      <div className="project-list-container">
        <Link to="/about" className="about-btn-container">
          <div className="btn-about box">About me</div>
        </Link>
        {location.pathname === "/about" && (
          <div className="only-tablet">
            <About />
          </div>
        )}
        <Link to={"/"}>
          <div className="contact-info box" style={{ paddingBottom: "10px" }}>
            <img src="/images/logo.png" alt="" />

            <h2>
              <strong>Hello I'm Pablo.</strong> And I made this website for you.
            </h2>
          </div>
        </Link>
        <ProjectList />
      </div>
      <div className="router-container only-desktop">
        <Outlet />
      </div>
    </div>
  );
}

export default App;
