import React from 'react'
import { useRouteError } from 'react-router-dom';

export default function Error() {
  const error = useRouteError();
  console.error(error);

  return (
    <div id="error-page">
      <h1>Oops! Some error happened:</h1>
      <h2 className='error'>{error.statusText || error.message}
      </h2>
    </div>
  )

}
